// components/ChatMessage.tsx
import React, { memo } from 'react';
import SQLResultMessage from './messages/SQLResultMessage'
import SuggestionsMessage from './messages/SuggestionsMessage';
import PreChatMessage from './messages/PreChatMessage';
import PersonIcon from '@mui/icons-material/Person';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import brainIcon from './icons/brain.svg';
import OnlySQLStatementTab from './OnlySQLStatementTab';
import LinkIcon from '@mui/icons-material/Link';
import UserMessage from './messages/UserMessage';

function getMessageClassName(type: string) {
  switch (type) {
      case 'user':
          return 'user-message';
      case 'bot':
          return 'bot-message';
      case 'complete':
          return 'complete-message';
      case 'pre_chat': // Add case for pre_chat
          return 'pre-chat-message'; // Class name for pre_chat messages
      default:
          return '';
  }
}

// type TableProps = {
//   columns: Column<object>[]; 
//   data: any[];
// };

type ChatMessageProps = {
  message: any; 
  executeSQLStatement: any;
  handleSubmitWrapper: any;
  setPromptId:any ;
  setSqlQuery:any;
};

const ChatMessage: React.FC<ChatMessageProps> = memo(({ message, executeSQLStatement, handleSubmitWrapper,setPromptId ,setSqlQuery}) => {

  if (message.type === 'sql_result') {
    return <SQLResultMessage message={message} executeSQLStatement={executeSQLStatement} />;
  } else if (message.type === 'suggestions') {
    return <SuggestionsMessage suggestions={message.suggestions} />;
  } else if (message.type === 'sql_query') {
    return <OnlySQLStatementTab message={message}  executeSQLStatement={executeSQLStatement} />;
  } else if (message.type === 'pre_chat') {
    return <PreChatMessage message={message} handleSubmitWrapper={handleSubmitWrapper} />;
  }
  return (
    <div className={`chat-message ${getMessageClassName(message.type)}`}>
      {message.type === 'user' ? (
        <PersonIcon className="message-icon" color="primary" />
      ) : message.type === 'bot' ? (
        <SmartToyIcon color="primary" className="message-icon" />
      ) : message.type === 'complete' ? (
        <div className="icon-text-container">
          <img src={brainIcon} alt="Brain Icon" className="message-icon" />
          <h4>Smart Insights:</h4>
        </div>
      ) : null}

      {message.type === 'user' ? (
        <UserMessage text={message.text} promptId={message.prompt_id} /> // Use the UserMessage component
      ) : message.type === 'complete' ? (
        <p>{message.text}</p>
      ) : (
        message.text
      )}
    </div>
  );
});
  

export default ChatMessage;
