import React, { useRef, useEffect, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress'; 
import './ChatForm.css';

const ChatForm = ({
  handleSubmitWrapper,
  prompt,
  fileName,
  setFileName,
  fileOptions,
  isLoading,
  setHasStartedChat,
}: {
  handleSubmitWrapper: any;
  prompt: string;
  fileName: { NNAME: string; FILENAME: string } | null; // Specify type
  setFileName: (value: { NNAME: string; FILENAME: string } | null) => void; // Specify type
  fileOptions: { NNAME: string; FILENAME: string }[];
  isLoading: boolean;
  setHasStartedChat: (value: boolean) => void;
}) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [localPrompt, setLocalPrompt] = useState(prompt);

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  // useEffect(() => {
  //   adjustHeight();
  // }, [localPrompt]);

  useEffect(() => {
    // Automatically set the fileName if the corresponding file option exists
    const defaultFile = fileOptions.find(file => file.FILENAME === 'FULFILLMENT_ORDER_ITEMS.yaml');
    if (defaultFile && !fileName) {
      setFileName({ NNAME: defaultFile.NNAME, FILENAME: defaultFile.FILENAME }); // Set fileName to the object
    }
  }, [fileOptions, fileName, setFileName]);

  // Effect to change hasStartedChat state when fileName changes
  useEffect(() => {
    if (fileName) {
      setHasStartedChat(true); // Set to true when a file is selected
    } else {
      setHasStartedChat(false); // Set to false if no file is selected
    }
  }, [fileName, setHasStartedChat]);



  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      // Call handleSubmitWrapper with the localPrompt when the Enter key is pressed
      handleSubmitWrapper(null, localPrompt);
      // Optionally, clear the local prompt after submission
      setLocalPrompt('');
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent default form submission
    // Call handleSubmitWrapper with the localPrompt when the form is submitted
    handleSubmitWrapper(null, localPrompt);
    // Optionally, clear the local prompt after submission
    setLocalPrompt('');
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLocalPrompt(e.target.value);
    adjustHeight();
  };



  const handleFileChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedFileName = e.target.value;
    const selectedFile = fileOptions.find(file => file.FILENAME === selectedFileName);
    if (selectedFile) {
      setFileName({ NNAME: selectedFile.NNAME, FILENAME: selectedFile.FILENAME }); // Set fileName to the object
    } else {
      setFileName(null); // If no file is selected, set to null
    }
  };

  return (
    <form className="chat-input-container" onSubmit={handleSubmit}>
      <div className="textarea-container">
        <textarea
          ref={textareaRef}
          placeholder="Type your message here..."
          value={localPrompt}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          className="chat-input"
        />
        <IconButton
          type="submit"
          className="submit-button"
          disabled={isLoading}
          aria-label="send"
        >
          {isLoading ? <CircularProgress size={24} /> : <SendIcon />}
        </IconButton>
      </div>
      <select
        value={fileName?.FILENAME || ''} // Use FILENAME to control the select value
        onChange={handleFileChange} // Call the new handler here
        className="file-select"
        disabled={!fileOptions.length}
      >
        <option value="" disabled>
          {fileOptions.length ? 'Select a file' : 'Loading files...'}
        </option>
        {fileOptions.map((file) => (
          <option key={file.FILENAME} value={file.FILENAME}>
            {file.NNAME}
          </option>
        ))}
      </select>
    </form>
  );
};

export default ChatForm;
