// components/SuggestionsMessage.tsx
import React from 'react';

const SuggestionsMessage: React.FC<{ suggestions: any }> = ({ suggestions }) => {
  return (
    <div className="chat-message suggestion-message">
      <h4>Suggestions:</h4>
      <ul>
        {Array.isArray(suggestions) && suggestions.map((suggestion, index) => (
          <li className="suggestion-item" key={index}>{suggestion}</li>
        ))}
      </ul>
    </div>
  );
};

export default SuggestionsMessage;
