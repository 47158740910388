import React, { useState, useMemo } from 'react';
import { useTable, useSortBy, Column } from 'react-table';
import './TableComponent.css';

type TableProps = {
  columns: Column<object>[]; 
  data: any[];
};

const TableComponent: React.FC<TableProps> = ({ columns, data }) => {
  const [searchTerm, setSearchTerm] = useState<string>(''); // State to track the search input

  // Use useMemo to prevent recalculating filteredData on every render
  const filteredData = useMemo(() => {
    if (!data) return []; // Return an empty array if `data` is null or undefined
  
    return data.filter(row => 
      Object.values(row).some(value => 
        value !== null && value !== undefined && 
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [data, searchTerm]);


  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data: filteredData },  // Pass filteredData to useTable
    useSortBy // Enable sorting
  );

  return (
    <div className="table-container">
      {/* Add a search input */}
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
        className="search-input"
      />

      <table {...getTableProps()} className="table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps((column as any).getSortByToggleProps())}
                  className={
                    (column as any).isSorted
                      ? (column as any).isSortedDesc
                        ? 'sort-desc'
                        : 'sort-asc'
                      : ''
                  }
                >
                  {column.render('Header')}
                  <span>
                    {(column as any).isSorted ? ((column as any).isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.value}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
