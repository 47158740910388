// components/SQLStatementTab.tsx
import React, { useRef } from 'react';
import codeIcon from './icons/code.svg';
import SQLStatement from './SQLStatement';

import './ChatMessage.css';

const OnlySQLStatementTab: React.FC<{ 
  message: any, 
  executeSQLStatement: any
}> = ({ message, executeSQLStatement }) => {
  // Check if SQL statement exists
  const hasSqlQuery = message.sql;

  // Use a ref to store the promptId locally for each instance of the component
  const localPromptId = message.prompt_id;
  
  const handleButtonClick = () => {
    executeSQLStatement(null,localPromptId,hasSqlQuery); // Call executeSQLStatement with the local promptId
  };

  return (
    <div className="chat-message-container">
      <br />
      <h4>Only SQL Statement</h4>
      <br />
      
      {/* SQL Query Tab */}
      {hasSqlQuery && (
        <div className="tab-content">
          <button 
            className="tab disable" 
            onClick={handleButtonClick} // Use the new handler
          >
            
            <img src={codeIcon} alt="SQL Query" className="tab-icon" />
            <span className="tab-text">SQL Query</span>
          </button>
          <div className="sql-query-message">
            <SQLStatement statement={message.sql} executeSQLStatement={handleButtonClick} SQLExecuted={false}/>
          </div>
        </div>
      )}
      
      {/* Fallback message if no SQL statement */}
      {!hasSqlQuery && (
        <div className="no-query-message">
          <p>No SQL query available.</p>
        </div>
      )}
    </div>
  );
};

export default OnlySQLStatementTab;
