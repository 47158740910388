// ApiService.ts

import { toast } from 'react-toastify';

const HOST = process.env[`REACT_APP_API_URL`];

export const ApiService = {
  async fetchFileNames(accessToken: string | undefined): Promise<any[]> {
    if (!accessToken) throw new Error("Access token is missing");

    const response = await fetch(`${HOST}/list`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    if (!response.ok) throw new Error(`Failed to fetch file names: ${response.status}`);

    return response.json();
  },

  async checkComplete(accessToken: string | undefined, prompt: string){
    if (!accessToken) throw new Error("Access token is missing");

    const response = await fetch(`${HOST}/complete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ prompt }),
      credentials: 'include',
    });

    if (!response.ok) throw new Error('Failed to check completion status');

    const result = await response.json();
    return result.length > 0 && !result[0][0].includes("FETCH THE DATA") ? result[0][0] : null;
  },

  async submitPrompt(
    accessToken: string | undefined,
    prompt: string,
    fileName: string,
    isMultiTurnEnabled: boolean,
    isCompleteEnabled: boolean,
    prompt_id:any
  ): Promise<any> {
    if (!accessToken) throw new Error("Access token is missing");
  
    try {
      const response = await fetch(`${HOST}/prompt`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          prompt,
          symentic_file_name: fileName,
          is_multi_turn_enabled: isMultiTurnEnabled,
          is_complete_enabled: isCompleteEnabled,
          prompt_id:prompt_id
        }),
        credentials: 'include',
      });
  
      if (!response.ok) {
        const result = await response.json();
        const errorMessage = result.message || 'Something went wrong. Please try again or contact admin.';
        toast.error(`Error: ${errorMessage}`); // Use toast for error messages
        throw new Error(errorMessage);
      }
  
      return response.json();
    } catch (error) {
      console.error("Error in submitPrompt:", error);
      toast.error('An error occurred while submitting the prompt. Please try again or contact admin.'); // User-friendly error toast
      throw error; // Re-throw error after showing toast
    }
  },

  async getSQL(
    prompt: string,
    fileName: string,
    accessToken: string | undefined,
    isMultiTurnEnabled: boolean,
    isCompleteEnabled: boolean,
    prompt_id:any
  ): Promise<any> { // Define the return type
    if (!accessToken) throw new Error("Access token is missing");
  
    try {
      const response = await fetch(`${HOST}/cortex_analyst`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          prompt,
          symentic_file_name: fileName,
          is_multi_turn_enabled: isMultiTurnEnabled,
          is_complete_enabled: isCompleteEnabled,
          prompt_id:prompt_id
        }),
        credentials: 'include',
      });
  
      const result = await response.json();
  
      if (!response.ok) {
        const errorMessage = result.message || 'An unknown error occurred';
        alert(`Error: ${errorMessage}`); // Alert the user about the error
        throw new Error(errorMessage);
      }
  
      return result;
    } catch (error) {
      console.error("Error in getSQL:", error);
      throw error;
    }
  },

  async executeSQL(accessToken: string | undefined,prompt_id: string):Promise<any>{
    try {
      const response = await fetch(`${HOST}/execute?prompt_id=${prompt_id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        credentials: 'include',
      });
  
      const result = await response.json();
      if (!response.ok) {
        const errorMessage = result.message || 'An unknown error occurred';
        alert(`Error: ${errorMessage}`); // Alert the user about the error
        throw new Error(errorMessage);
      }
  
      return result;
    } catch (error) {
      console.error("Error in submitPrompt:", error);
      throw error;
    }
  },

  async fetchChatHistory(accessToken: string | undefined,fileName:String): Promise<any> {
    if (!accessToken) throw new Error("Access token is missing");

    try {
      const response = await fetch(`${HOST}/chathistory?filename=${fileName}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        credentials: 'include',
      });

      if (!response.ok) {
        const errorMessage = await response.json().then(res => res.message || 'An unknown error occurred');
        alert(`Error: ${errorMessage}`); // Alert the user about the error
        throw new Error(errorMessage);
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error in fetchChatHistory:", error);
      throw error;
    }
  },

  async getByPromptId(accessToken: string | undefined,promptId:String): Promise<any> {
    if (!accessToken) throw new Error("Access token is missing");

    try {
      const response = await fetch(`${HOST}/prompt_id?prompt_id=${promptId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        credentials: 'include',
      });

      if (!response.ok) {
        const errorMessage = await response.json().then(res => res.message || 'An unknown error occurred');
        alert(`Error: ${errorMessage}`); // Alert the user about the error
        throw new Error(errorMessage);
      }

      const result = await response.json();
      console.log("Chat History Result:", result); // Log the result
      return result;
    } catch (error) {
      console.error("Error in fetchChatHistory:", error);
      throw error;
    }
  }

}
