// components/PreChatMessage.tsx
import React from 'react';
import HistoryIcon from '@mui/icons-material/History';

const PreChatMessage: React.FC<{ message: any; handleSubmitWrapper:any }> = ({ message, handleSubmitWrapper }) => {
  const handleTryItClick = () => {
    const prompt = message.text;
    handleSubmitWrapper(null,prompt);
  };

  return (
    <div className={`chat-message pre-chat-message fade-in`}>
      <HistoryIcon className="message-icon" color="primary" />
      <span>{message.text}</span>
      <button className="try-it-button" onClick={handleTryItClick}>
        Try it.
      </button>
    </div>
  );
};

export default PreChatMessage;
