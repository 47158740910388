import React from 'react';
import { AuthContext } from '../authContext';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Header from '@cimpress-technology/react-platform-header';

export const PlatformHeader: React.FC = () => {
    const { accessToken, profile, logout, login } = React.useContext(AuthContext);
 

    const loggedIn = !!accessToken;
    const appLinks:any = []

    return<Header
        appTitle="ChatMCP"
        
        profile={profile}
        onLogInClicked={login}
        onLogOutClicked={logout}
        isLoggedIn={loggedIn}
        accessToken={accessToken}
    />;
};
