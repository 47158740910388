import React, { createContext, useContext, useMemo } from 'react';
import { useAuthentication, AuthenticationProps } from './auth';

export const AuthContext = createContext({} as AuthenticationProps);

export const AuthProvider: React.FC<{
    children: React.ReactElement;
}> = React.memo((props) => {
    const auth = useAuthentication();

    // Memoize the auth value to prevent unnecessary re-renders
    const memoizedAuth = useMemo(() => auth, [auth]);

    return (
        <AuthContext.Provider value={memoizedAuth}>
            {props.children}
        </AuthContext.Provider>
    );
});
