import React, { useState, useEffect } from 'react';
import './FilterPromptGenerator.css';

interface Filter {
  column: string;
  operator: string;
  value: string;
}

interface FilterPromptGeneratorProps {
  columnOptions: { name: string; type: string }[];
  setFilter: (filterString: string) => void;
}

const FilterPromptGenerator: React.FC<FilterPromptGeneratorProps> = ({ columnOptions, setFilter }) => {
  const [selectedColumn, setSelectedColumn] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [activeFilters, setActiveFilters] = useState<Filter[]>([]);

  // Example column options with types
  const columnOptions1 = [
    { name: "MERCHANTID", type: "string" },
    { name: "ORDER_CREATEDDATE", type: "date" },
    { name: "PRODUCT_CATALOG_NAME", type: "string" },
    { name: "FULFILLERNAME", type: "string" },
  ];

  const filterOptions = [
    { label: 'Last X Days', value: 'LAST_X_DAYS' },
    { label: 'Greater Than', value: '>' },
    { label: 'Less Than', value: '<' },
    { label: 'Equal To', value: '=' },
    { label: 'Not Equal To', value: '!=' },
    { label: 'Greater Than or Equal To', value: '>=' },
    { label: 'Less Than or Equal To', value: '<=' },
    { label: 'Contains', value: 'CONTAINS' },
  ];

  useEffect(() => {
    if (activeFilters.length > 0) {
      const filterString = activeFilters
        .map(filter => `(${filter.column} ${filter.operator === 'CONTAINS' ? 'LIKE' : filter.operator} '${filter.value}')`)
        .join(' AND ');
      setFilter(`AND FILTER ON ${filterString}`);
      console.log("Generated Filter String:", `AND FILTER ON ${filterString}`);
    } else {
      setFilter(''); // Clear filter if no active filters
    }
  }, [activeFilters, setFilter]);

  const addFilter = () => {
    if (selectedColumn && selectedFilter) {
      let newValue = filterValue;
      let newOperator = selectedFilter;

      // Handle "Last X Days" filter
      if (selectedFilter === 'LAST_X_DAYS') {
        newValue = `In Last ${filterValue} days`; // Format it accordingly
        newOperator = '';
      }
      // Handle "Contains" filter
      else if (selectedFilter === 'CONTAINS') {
        newValue = `${filterValue}`; // Wrap value with % for SQL LIKE pattern
        newOperator = 'LIKE';
      }

      const newFilter: Filter = { column: selectedColumn, operator: newOperator, value: newValue };
      setActiveFilters([...activeFilters, newFilter]);

      // Reset selection
      setSelectedColumn('');
      setSelectedFilter('');
      setFilterValue('');
    }
  };

  const removeFilter = (index: number) => {
    const updatedFilters = activeFilters.filter((_, i) => i !== index);
    setActiveFilters(updatedFilters);
  };

  return (
    <div className="filter-generator">
      <div className="filter-controls">
        Filters:
        <div className="filter-dropdown">
          <select value={selectedColumn} onChange={(e) => setSelectedColumn(e.target.value)}>
            <option value="">Select a column</option>
            {columnOptions1.map((column) => (
              <option key={column.name} value={column.name}>{column.name}</option>
            ))}
          </select>
        </div>

        <div className="filter-dropdown">
          <select value={selectedFilter} onChange={(e) => setSelectedFilter(e.target.value)}>
            <option value="">Select filter type</option>
            {filterOptions.map((filter) => (
              <option key={filter.label} value={filter.value}>{filter.label}</option>
            ))}
          </select>
        </div>

        <div className="filter-dropdown">
          <input
            type="text"
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            placeholder="Enter value"
          />
        </div>

        <button className="add-filter-btn" onClick={addFilter}>Add Filter</button>

        <div className="active-filters">
          {activeFilters.map((filter, index) => (
            <div key={index} className="filter-chip">
              <span>{filter.column} {filter.operator} {filter.value}</span>
              <button className="remove-filter" onClick={() => removeFilter(index)}>✕</button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FilterPromptGenerator;
