import React, { useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
import './ChartComponent.css';

// Register necessary chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

interface ChartComponentProps {
  data: any;
  chartType: 'bar' | 'line';
  metadata: { width: number; height: number }; // New metadata prop
}

const ChartComponent: React.FC<ChartComponentProps> = ({ data, chartType: initialChartType, metadata }) => {
  const [chartType, setChartType] = useState<'bar' | 'line'>(initialChartType);
  const [xColumn, setXColumn] = useState<string>('');
  const [yColumn, setYColumn] = useState<string>('');

  // Temporary state to hold the selections
  const [tempXColumn, setTempXColumn] = useState<string>('');
  const [tempYColumn, setTempYColumn] = useState<string>('');

  const columns = data.length > 0 ? Object.keys(data[0]) : [];

  // Set default values for X and Y columns when component mounts or data changes
  useEffect(() => {
    if (columns.length >= 2 && !xColumn && !yColumn) { // Check if xColumn and yColumn are not already set
      setXColumn(columns[0]); // Default to first column for X-Axis
      setYColumn(columns[1]); // Default to second column for Y-Axis
      setTempXColumn(columns[0]); // Set temp to default as well
      setTempYColumn(columns[1]); // Set temp to default as well
    }
  }, [columns, xColumn, yColumn]); // Include xColumn and yColumn in dependency array

  const chartData = {
    labels: data.map((item: any) => item[xColumn]),
    datasets: [
      {
        label: yColumn,
        data: data.map((item: any) => item[yColumn]),
        backgroundColor: (ctx: any) => {
          const chart = ctx.chart;
          const { ctx: chartCtx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }
          const gradient = chartCtx.createLinearGradient(0, 0, 0, chartArea.bottom);
          gradient.addColorStop(0, 'rgba(75, 192, 192, 0.7)');
          gradient.addColorStop(1, 'rgba(153, 102, 255, 0.5)');
          return gradient;
        },
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
        hoverBackgroundColor: 'rgba(255, 99, 132, 0.7)',
        hoverBorderColor: 'rgba(255, 99, 132, 1)',
        hoverBorderWidth: 3,
      },
    ],
  };

  const options = {
    responsive: true,
    animation: {
      duration: 1000,
      easing: 'easeOutBounce',
    },
    plugins: {
      legend: {
        labels: {
          color: '#4B5563',
          font: {
            size: 14,
            weight: 'bold',
          },
          padding: 20,
        },
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        titleFont: { size: 16, weight: 'bold' },
        bodyFont: { size: 14 },
        padding: 10,
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#4B5563',
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: '#4B5563',
          stepSize: 20,
        },
        grid: {
          color: 'rgba(200, 200, 200, 0.3)',
        },
      },
    },
  } as const;

  const handleChartTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setChartType(event.target.value as 'bar' | 'line');
  };

  const handleXChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setTempXColumn(selectedValue);
    setXColumn(selectedValue); // Update X-Axis immediately
  };

  const handleYChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setTempYColumn(selectedValue);
    setYColumn(selectedValue); // Update Y-Axis immediately
  };

  return (
    <div className="chart-settings-container">
      <div className="axis-selectors">
        <label className="axis-label">
          X-Axis:
          <select value={tempXColumn} onChange={handleXChange} className="axis-dropdown">
            <option value="" disabled>Select X-axis</option>
            {columns.map((col) => (
              <option key={col} value={col}>
                {col}
              </option>
            ))}
          </select>
        </label>

        <label className="axis-label">
          Y-Axis:
          <select value={tempYColumn} onChange={handleYChange} className="axis-dropdown">
            <option value="" disabled>Select Y-axis</option>
            {columns.map((col) => (
              <option key={col} value={col}>
                {col}
              </option>
            ))}
          </select>
        </label>

        <label className="axis-label">
          Chart Type:
          <select value={chartType} onChange={handleChartTypeChange} className="chart-type-dropdown">
            <option value="bar">Bar</option>
            <option value="line">Line</option>
          </select>
        </label>
      </div>

      <ResizableBox
        className="chart-container"
        width={metadata.width}
        height={metadata.height}
        minConstraints={[700, 300]} // Minimum size
        maxConstraints={[1000, 600]} // Maximum size
        axis="both"
        resizeHandles={['se']}>
          
        {chartType === 'bar' ? (
          <Bar data={chartData} options={options} />
        ) : (
          <Line data={chartData} options={options} />
        )}
      </ResizableBox>
    </div>
  );
};

export default ChartComponent;
