// components/SQLResultMessage.tsx
import React, { Suspense, useState } from 'react';
import TableComponent from '../TableComponent';
import ChartComponent from '../ChartComponent';
import resultsIcon from '../icons/results.svg';
import reportIcon from '../icons/report.svg';
import codeIcon from '../icons/code.svg';
import SQLStatement from '../SQLStatement';

const SQLResultMessage: React.FC<{ message: any; executeSQLStatement: () => void }> = ({ message, executeSQLStatement }) => {
  const [activeTab, setActiveTab] = useState<'sql' | 'chart' | 'query'>('sql');

  const handleTabChange = (tab: 'sql' | 'chart' | 'query') => {
    setActiveTab(tab);
  };

  return (
    <div className="chat-message-container">
      <h4>SQL Results and Visualization</h4>
      <div className="tabs">
        <button className={`tab ${activeTab === 'sql' ? 'active' : ''}`} onClick={() => handleTabChange('sql')}>
          <img src={resultsIcon} alt="SQL Result" className="tab-icon" />
          <span className="tab-text">SQL Result</span>
        </button>
        <button className={`tab ${activeTab === 'chart' ? 'active' : ''}`} onClick={() => handleTabChange('chart')}>
          <img src={reportIcon} alt="Data Visualization" className="tab-icon" />
          <span className="tab-text">Data Visualization</span>
        </button>
        <button className={`tab ${activeTab === 'query' ? 'active' : ''}`} onClick={() => handleTabChange('query')}>
          <img src={codeIcon} alt="SQL Query" className="tab-icon" />
          <span className="tab-text">SQL Query</span>
        </button>
      </div>
      <div className="tab-content">
        {activeTab === 'sql' && (
          <div className="sql-message">
            {message.data.length ? (
              <TableComponent columns={message.columns} data={message.data} />
            ) : (
              <>
                <TableComponent columns={message.columns} data={message.data} />
                <p>No results found. Please check the SQL in the <strong>SQL Query Tab</strong>.</p>
              </>
            )}
          </div>
        )}
        {activeTab === 'chart' && (
          <div className="chart-message">
            {!message.data.length ? (
              <p>No results found. Click on SQL Query Tab to view the query.</p>
            ) : (
              <Suspense fallback={<div>Loading Chart...</div>}>
                <ChartComponent data={message.data} chartType="bar" metadata={{ width: 900, height: 400 }} />
              </Suspense>
            )}
          </div>
        )}
        {activeTab === 'query' && (
          <div className="sql-query-message">
            <SQLStatement statement={message.sql} executeSQLStatement={executeSQLStatement} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SQLResultMessage;
