import React, { useState } from 'react';
import LinkIcon from '@mui/icons-material/Link';

const UserMessage: React.FC<{ text: any; promptId:any }> = ({ text, promptId }) => {
  const [copyMessage, setCopyMessage] = useState('');
  const HOST = process.env[`REACT_APP_API_URL`];
  const handleCopy = () => {
    navigator.clipboard.writeText(`${window.location.origin}/?promptId=${promptId}`);
    setCopyMessage('Link copied!'); // Set the message when the link is copied
    setTimeout(() => setCopyMessage(''), 2000); // Clear the message after 2 seconds
  };

  return (
    <div className="user-message">
      <span className="message-text">{text}</span>
      <LinkIcon
        className="link-icon"
        onClick={handleCopy} // Call handleCopy on click
        titleAccess="Copy URL"
        style={{ cursor: 'pointer' }} // Keep cursor as pointer
      />
      {copyMessage && <span className="copy-feedback">{copyMessage}</span>} {/* Display the copied message */}
    </div>
  );
};

export default UserMessage;
