
import { useState, useEffect } from 'react';
import { centralizedAuth } from '@cimpress/simple-auth-wrapper';
import * as Sentry from '@sentry/react';

const CLIENT_ID = 't3T6M4n5Xw8pRV4ZnMXw4Z0qQ9vAv8rc'; // PDW Access UI

export const authWrapper = new centralizedAuth({
    clientID: CLIENT_ID,
    redirectRoute: '/',
    emitInitialTokenExpired: false
});

authWrapper.on('tokenExpired', async () => {
    const nextUri = window.location.pathname || '/';
    

    await authWrapper.login({ nextUri, forceLogin: true });
    
});


export interface Auth0Profile {
    email: string;
    email_verified: boolean;
    family_name: string;
    given_name: string;
    name: string;
    nickname: string;
    picture: string;
    sub: string;
    updated_at: string;
    'https://claims.cimpress.io/account': string;
    'https://claims.cimpress.io/canonical_id': string;
    'https://claims.cimpress.io/cimpress_internal': boolean;
    'https://claims.cimpress.io/email': string;
    'https://claims.cimpress.io/tenants': string[];
    'https://claims.cimpress.io/was': string[];
}

export interface AuthenticationProps {
    isAuthenticating: boolean;
    profile?: Auth0Profile;
    authenticationError?: Error;
    accessToken?: string;
    login: () => Promise<void>;
    logout: () => Promise<void>;
}


export const useAuthentication = (): AuthenticationProps => {

    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const [accessToken, setAccessToken] = useState("");
    const [error, setError] = useState(undefined);
    const [profile, setProfile] = useState(undefined as Auth0Profile | undefined);


    const logout = async () => {
        await authWrapper.logout('/');
    };

    const login = async () => {
        if (!accessToken) {

            const nextUri = window.location.pathname + window.location.search;
            setError(undefined);
            setIsAuthenticating(true);
            try {
                await authWrapper.ensureAuthentication({ nextUri });
                console.log("ensureAuthentication")
                const profile = authWrapper.getProfile() as Auth0Profile;
                const accessToken = authWrapper.getAccessToken() || '';
                setProfile(profile);
                console.log(profile)
                setAccessToken(accessToken);
            } catch (error) {
                // eslint-disable-next-line
                setError(error as any);
            }
            setIsAuthenticating(false);
        }

    };

    useEffect(() => {
        login();
    }, [accessToken]);

    useEffect(() => {

        // To stay under the limit of sessions of the free tiers ignoring these users.
        const ignoredUsers = [
            'vcuenagarcia@cimpress.com'
        ];

        // Set user on reporting apps
        if (profile && window.location.hostname !== 'localhost' && !ignoredUsers.includes(profile.email)) {

            // Set up sentry
            Sentry.setUser({
                email: profile.email,
                id: profile.sub,
                username: profile.name
            });
        }
    }, [profile]);

    return {
        isAuthenticating,
        profile,
        accessToken,
        authenticationError: error,
        login,
        logout
    };
};
